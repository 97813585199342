<template>
    <default-app-template>
        <basic-headline>Settings</basic-headline>

        <div class="max-w-md mx-auto">
            <p class="font-bold my-5 text-center text-gray-500">
                Store settings and metadata which will be used throughout the
                application.
            </p>

            <FormulateForm
                v-model="settings"
                class="settings-form"
                @submit="save()"
            >
                <FormulateInput
                    name="country"
                    :options="{
                        DE: 'DE',
                        CH: 'CH',
                        AT: 'AT',
                    }"
                    type="select"
                    label="Country"
                    help="This setting is used for Patient ID generation."
                    placeholder="Country"
                    validation="required"
                />

                <FormulateInput
                    name="device"
                    type="text"
                    label="Measurement Device"
                    placeholder="Measurement Device"
                    validation="required"
                />

                <div class="flex justify-end my-5">
                    <div>
                        <basic-button
                            :loading="isLoading"
                            type="submit"
                            icon="check"
                            >Save</basic-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </div>
    </default-app-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
    data() {
        return {
            settings: {},
            keys: ['country', 'device'],
        }
    },
    computed: {
        ...mapGetters('settings', [
            'isLoading', //also supports payload `this.nameOfAction(amount)`
        ]),
    },
    mounted() {
        this.getSettings()
            .then(() => {
                this.setSettings()
            })
            .catch(() => {
                this.$toast.error('Error while trying to load Settings')
            })
    },
    methods: {
        setSettings() {
            // For security reasons, we pick settings by hand
            let newVals = {}
            _.each(this.keys, key => {
                newVals[key] = _.get(this.$store.state.settings, key, null)
            })
            this.settings = newVals
        },
        save() {
            this.saveSettings(this.settings)
                .then(() => {
                    this.setSettings()
                    this.$toast.success('Settings saved')
                })
                .catch(() => {
                    this.$toast.error('Error while trying to save Settings')
                })
        },

        ...mapActions('settings', [
            'getSettings', //also supports payload `this.nameOfAction(amount)`
            'saveSettings',
        ]),
    },
}
</script>
